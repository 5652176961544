import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Container, HeroImage, ProductList } from '@components'
import { useProducts } from '@hooks'
import { MediaType } from '@types'

import { DefaultLayout } from '../layouts/defaultlayout'

export const query = graphql`
  query {
    products: directus {
      ...ProductsFragment
    }
  }
`

interface Props {
  data: {
    products: {
      products: {
        hero: MediaType
        title: string
        subtitle: string
      }
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ data }: Props) => {
  const { hero, title, subtitle } = data.products.products
  const [products, isProductsLoaded] = useProducts()
  return (
    <DefaultLayout isDataLoaded={isProductsLoaded}>
      <Helmet>
        <title>Hell-O-Chili - Produkte</title>
      </Helmet>
      <HeroImage imageSrc={hero.mediaUrl.publicURL} />
      <Container>{isProductsLoaded && <ProductList products={products} title={title} subtitle={subtitle} />}</Container>
    </DefaultLayout>
  )
}
